import { useEffect, useState } from "react";
import { setConfig } from "../../environment/config";
import { setToken } from "../../api/auth/authSlice";
import { useDispatch } from "react-redux";
import { useGoogleLoginMutation } from "../../api/auth/authApiSlice";
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { Paper, Card, CardHeader, CardContent, Box, Button, Stack, Typography, useTheme } from "@mui/material";
import {
    GsiButton,
    GsiClient,
    IdTokenProvider,
    useIdToken,
    useOneTap
} from 'react-gsi';

const idConfiguration = {
    client_id: '1089792506784-qtpgve6p228ki0pkif8thac7esal2tmf.apps.googleusercontent.com',
    auto_select: true
}

const buttonConfiguration = {
    type: 'standard',
    theme: 'filled_black',
    size: 'large',
    shape: 'pill',
    width: '220px'
}

function GoogleLoginSigner({onSuccess}) {
    const [loginCredentials, setLoginCredentials] = useState(null);
    const [login] = useGoogleLoginMutation();
    const token = useIdToken();
    const dispatch = useDispatch();

    useEffect(() => {
        async function performLogin() {
            if (loginCredentials) {
                const response = await login({"idToken": loginCredentials}).unwrap();
                console.log(response);
                dispatch(setToken(response.token))
                onSuccess();
            }
        };
        performLogin();
    }, [loginCredentials]);

    if(token) {
        if(token.credential) {
            if(loginCredentials !== token.credential) {
                setLoginCredentials(token.credential);
            }
        }
    }

    return <GsiButton configuration={buttonConfiguration} />
}

function GoogleLoginButton({onSuccess}) {
    return <GsiClient>
        <IdTokenProvider configuration={idConfiguration}>
            <GoogleLoginSigner onSuccess={onSuccess}/>
        </IdTokenProvider>
    </GsiClient>
}

export { GoogleLoginButton };
