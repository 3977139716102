import { Stack } from "@mui/material";
import { CompanyRESTView } from "../../components/model/Company";

const CompanyAdminView = () => {
    return <Stack direction="column" sx={{mt: 4, alignSelf: "center"}} spacing={4}>
            <CompanyRESTView />
        </Stack>;
}

export { CompanyAdminView };
