
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const personbadgeApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listPersonBadges: builder.query ({
      query: (params) => `v2/personbadge/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["PersonBadge"]
    }),
    getPersonBadge: builder.query ({
      query: (personbadge_id) => `v2/personbadge/${personbadge_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["PersonBadge"]
    }),

    updatePersonBadge: builder.mutation ({
      query: ({personbadge_id, data}) => ({
        url: `v2/personbadge/${personbadge_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["PersonBadge"]
    }),
    deletePersonBadge: builder.mutation ({
      query: (personbadge_id) => ({
        url: `v2/personbadge/${personbadge_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["PersonBadge"]
    }),
    createPersonBadge: builder.mutation ({
      query: (data) => ({
        url: `v2/personbadge/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["PersonBadge"]
    }),
  })
})


export const {
  useListPersonBadgesQuery,
  useGetPersonBadgeQuery,

  useUpdatePersonBadgeMutation,
  useDeletePersonBadgeMutation,
  useCreatePersonBadgeMutation
} = personbadgeApi
