import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { logOut } from './auth/authSlice'
import Cookies from 'js-cookie';

const baseQuery = fetchBaseQuery(
    {
        baseUrl: process.env.REACT_APP_API_URL || "https://api.sourceme.app/api/",
        credentials: 'include',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.access_token
            if (token) {
                headers.set("authorization", `Bearer ${token}`)
            }
            return headers
        },
        validateStatus: (response, result) => {
            if (response.status === 401 || response.status === 403) {
                Cookies.set('auth_access_token', null);
                return false;
            }
            return true;
        }
    }
)

const baseQueryWithAuthHandling = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result?.error?.status === 401) {
        console.log("Logging out");
        api.dispatch(logOut());
        apiSlice.util.resetApiState();
    }
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithAuthHandling,
    endpoints: builder => ({}),
    tagTypes: ["Person", "Resume", "Document", "QuestionList", "Question", "Job", "JobQuestionList", "ResumeSkill", "Notification", "Match", "JobRequirement", "Feed"]
})
