import { useUploadResumeMutation } from "../features/uploadSlice";
import { useGetPersonResumesQuery } from "../features/model/personSlice";
import { useDeleteResumeMutation, useUpdateResumeMutation } from "../features/model/resumeSlice";
import { useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Fade, IconButton, Paper, Stack, Typography } from "@mui/material";
import { formatUTCDate } from "../environment/dates";
import Loading from "../components/Loading";
import Uploader from "../components/Uploader";
import Viewer from "../components/Viewer";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';

function ResumeCard({resume}) {
    const [more, setMore] = useState(false);
    const [deleteResume] = useDeleteResumeMutation();
    const [updateResume] = useUpdateResumeMutation();
    const [name, setName] = useState(resume.name ? resume.name : resume.documents[0]?.filename);

    let uploaded = resume.documents[0]?.created;
  
    return <Card variant="outlined">
        <CardHeader title={resume.name} action={<IconButton onClick={()=>{setMore(!more);}}><MoreVertIcon /></IconButton>} />
        <CardActions>
            <Button startIcon={<PreviewIcon />} variant="outlined" onClick={() => {
                Viewer(resume.documents[0], true);
            }}>View</Button>
            <Fade mountOnEnter unmountOnExit in={more}>
                <Button color="secondary" startIcon={<DeleteIcon />} variant="outlined" onClick={() => {
                    if (window.confirm(`Are you sure you want to delete the resume ${resume.documents[0].filename}?`)) {
                        deleteResume(resume.id);
                    }
                }}>Delete</Button>
            </Fade>
        </CardActions>
        <CardContent>
        <Fade mountOnEnter unmountOnExit in={more}>
            <Typography>Uploaded: {formatUTCDate(uploaded)}</Typography>
        </Fade>
        </CardContent>
      </Card>
  }
  
const ResumesView = ({userInfo}) => {
    const [uploadResume, {isLoading: isUploading }] = useUploadResumeMutation();
    
    const resumeQuery = useGetPersonResumesQuery(userInfo.id);
  
    if(!resumeQuery.data || resumeQuery.isLoading) return <Loading />;
  
    const onUploadResume = (file, data) => {
      uploadResume({data: data, filename: file.name});
    }
  
    return <Card variant="outlined">
        <CardHeader title="Resumés" />
        <CardContent>
            <Stack direction="column" spacing={2}>
            {resumeQuery.data.map((resume) => <ResumeCard key={resume.id} resume={resume} />)}
            {resumeQuery.data.length == 0 && <Typography>No resumés uploaded.</Typography>}
            <Uploader title="Upload resumé" onCancel={() => {}} onUpload={onUploadResume} disabled={isUploading} />
            </Stack>
        </CardContent>

        </Card>;
}

export { ResumesView }