
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const answerApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listAnswers: builder.query ({
      query: (params) => `v2/answer/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Answer"]
    }),
    getAnswer: builder.query ({
      query: (answer_id) => `v2/answer/${answer_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Answer"]
    }),

    updateAnswer: builder.mutation ({
      query: ({answer_id, data}) => ({
        url: `v2/answer/${answer_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Answer"]
    }),
    deleteAnswer: builder.mutation ({
      query: (answer_id) => ({
        url: `v2/answer/${answer_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Answer"]
    }),
    createAnswer: builder.mutation ({
      query: (data) => ({
        url: `v2/answer/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Answer"]
    }),
  })
})


export const {
  useListAnswersQuery,
  useGetAnswerQuery,

  useUpdateAnswerMutation,
  useDeleteAnswerMutation,
  useCreateAnswerMutation
} = answerApi
