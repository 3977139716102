
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const jobquestionlistApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listJobQuestionLists: builder.query ({
      query: (params) => `v2/jobquestionlist/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["JobQuestionList"]
    }),
    getJobQuestionList: builder.query ({
      query: (jobquestionlist_id) => `v2/jobquestionlist/${jobquestionlist_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["JobQuestionList"]
    }),

    updateJobQuestionList: builder.mutation ({
      query: ({jobquestionlist_id, data}) => ({
        url: `v2/jobquestionlist/${jobquestionlist_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["JobQuestionList"]
    }),
    deleteJobQuestionList: builder.mutation ({
      query: (jobquestionlist_id) => ({
        url: `v2/jobquestionlist/${jobquestionlist_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["JobQuestionList"]
    }),
    createJobQuestionList: builder.mutation ({
      query: (data) => ({
        url: `v2/jobquestionlist/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["JobQuestionList"]
    }),
  })
})


export const {
  useListJobQuestionListsQuery,
  useGetJobQuestionListQuery,

  useUpdateJobQuestionListMutation,
  useDeleteJobQuestionListMutation,
  useCreateJobQuestionListMutation
} = jobquestionlistApi
