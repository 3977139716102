
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const resumeApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listResumes: builder.query ({
      query: (params) => `v2/resume/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Resume"]
    }),
    getResume: builder.query ({
      query: (resume_id) => `v2/resume/${resume_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Resume"]
    }),
    getResumeActions: builder.query ({
      query: (resume_id) => `v2/action/?resume=${resume_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Action"]
    }),
    getResumeDocuments: builder.query ({
      query: (resume_id) => `v2/document/?resume=${resume_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Document"]
    }),
    getResumeMatches: builder.query ({
      query: (resume_id) => `v2/match/?resume=${resume_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Match"]
    }),
    getResumeSkills: builder.query ({
      query: (resume_id) => `v2/resumeskill/?resume=${resume_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["ResumeSkill"]
    }),
    updateResume: builder.mutation ({
      query: ({resume_id, data}) => ({
        url: `v2/resume/${resume_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Resume"]
    }),
    deleteResume: builder.mutation ({
      query: (resume_id) => ({
        url: `v2/resume/${resume_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Resume"]
    }),
    createResume: builder.mutation ({
      query: (data) => ({
        url: `v2/resume/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Resume"]
    }),
  })
})


export const {
  useListResumesQuery,
  useGetResumeQuery,
  useGetResumeActionsQuery,
  useGetResumeDocumentsQuery,
  useGetResumeMatchesQuery,
  useGetResumeSkillsQuery,
  useUpdateResumeMutation,
  useDeleteResumeMutation,
  useCreateResumeMutation
} = resumeApi
