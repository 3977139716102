import { Stack } from "@mui/material";
import { FeedRESTView } from "../../components/model/Feed";

const FeedAdminView = () => {
    return <Stack direction="column" sx={{mt: 4, alignSelf: "center"}} spacing={4}>
            <FeedRESTView />
        </Stack>;
}

export { FeedAdminView };
