import { getConfig } from "../environment/config";
import mime from 'mime';

function Viewer(doc, download=false) {
    var headers = new Headers();
    const token = getConfig("auth_access_token", null);
    if (token != null) {
        headers.set("Authorization", `Bearer ${token}`);
    }
    
    const filename = doc.filename;
    const url = `${process.env.REACT_APP_API_URL || "https://api.sourceme.app/api/"}v2/document/${doc.id}/?dl=1`;
    
    fetch(url, {headers: headers})
    .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .then((stream) => new Response(stream))
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob], {type: mime.getType(filename)}));
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        if(download)
          link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
}

export default Viewer;