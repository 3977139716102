
import { apiSlice } from '../api/apiSlice'

export const pollingApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    getHome: builder.query ({
        query: () => `v2/home/`,
        keepUnusedDataFor: 0
        }),
    getHomeFeed: builder.query ({
        query: () => `v2/feed/`,
        keepUnusedDataFor: 5,
        providesTags: ["Feed"]
    }),
    getNamedFeed: builder.query ({
        query: (name) => `v2/named_feed/${name}`,
        keepUnusedDataFor: 5,
        providesTags: ["Feed"]
    }),
    getPolling: builder.query ({
        query: () => `v2/poll/`,
        keepUnusedDataFor: 1,
        providesTags: ["Person", "Notification"]
        }),
    })
})

export const {
    useGetHomeQuery,
    useGetHomeFeedQuery,
    useGetNamedFeedQuery,
    useGetPollingQuery
} = pollingApi
