import React, { useState } from 'react';
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from "@mui/material";
import icon from "../images/icon.png";
import { logOut } from "../api/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetHomeQuery } from "../features/pollingSlice";
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const token = useSelector(state => state.auth.access_token);
    const [skipToken, setSkipToken] = useState(true);
    const user = useGetHomeQuery(undefined, {skip: skipToken});
    const dispatch = useDispatch()
    const handleLogout = (e) => {
      dispatch(logOut());
    }

    if(token !== null && skipToken) {
      setSkipToken(false);
    }
    if(token === null && !skipToken) {
      setSkipToken(true);
    }

    let sections = [];
    var username = "";
    if (user.data) {
      username = user.data.name;
      if(user.data.permissions) {
        if(user.data.permissions.includes("admin")) {
          sections.push(<Button key="admin" color="inherit" component={Link} to={"/admin"}>Admin</Button>);
        }
      }
    } else {
      return <></>;
    }

    

    return <AppBar position="static">
    <Toolbar variant="dense">
      <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} component={Link} to={"/"}>
        <img src={icon} height={24} className="topLogo" />
      </IconButton>
      <Typography variant="h6" color="inherit" component="div">
        SourceMe.app
      </Typography>
      <Box key="spacer" sx={{ flexGrow: 1 }} alignItems="center" textAlign="center" />
      {sections}
      {token && <Button key="logout" color="inherit" onClick={handleLogout}>Log out</Button>}
    </Toolbar>
  </AppBar>;
}

export default Header;
