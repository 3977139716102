
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const questionApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listQuestions: builder.query ({
      query: (params) => `v2/question/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Question"]
    }),
    getQuestion: builder.query ({
      query: (question_id) => `v2/question/${question_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Question"]
    }),

    updateQuestion: builder.mutation ({
      query: ({question_id, data}) => ({
        url: `v2/question/${question_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Question"]
    }),
    deleteQuestion: builder.mutation ({
      query: (question_id) => ({
        url: `v2/question/${question_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Question"]
    }),
    createQuestion: builder.mutation ({
      query: (data) => ({
        url: `v2/question/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Question"]
    }),
  })
})


export const {
  useListQuestionsQuery,
  useGetQuestionQuery,

  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
  useCreateQuestionMutation
} = questionApi
