
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const companycontactApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listCompanyContacts: builder.query ({
      query: (params) => `v2/companycontact/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["CompanyContact"]
    }),
    getCompanyContact: builder.query ({
      query: (companycontact_id) => `v2/companycontact/${companycontact_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["CompanyContact"]
    }),

    updateCompanyContact: builder.mutation ({
      query: ({companycontact_id, data}) => ({
        url: `v2/companycontact/${companycontact_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["CompanyContact"]
    }),
    deleteCompanyContact: builder.mutation ({
      query: (companycontact_id) => ({
        url: `v2/companycontact/${companycontact_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["CompanyContact"]
    }),
    createCompanyContact: builder.mutation ({
      query: (data) => ({
        url: `v2/companycontact/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["CompanyContact"]
    }),
  })
})


export const {
  useListCompanyContactsQuery,
  useGetCompanyContactQuery,

  useUpdateCompanyContactMutation,
  useDeleteCompanyContactMutation,
  useCreateCompanyContactMutation
} = companycontactApi
