
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const notificationApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listNotifications: builder.query ({
      query: (params) => `v2/notification/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Notification"]
    }),
    getNotification: builder.query ({
      query: (notification_id) => `v2/notification/${notification_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Notification"]
    }),

    updateNotification: builder.mutation ({
      query: ({notification_id, data}) => ({
        url: `v2/notification/${notification_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Notification"]
    }),
    deleteNotification: builder.mutation ({
      query: (notification_id) => ({
        url: `v2/notification/${notification_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Notification"]
    }),
    createNotification: builder.mutation ({
      query: (data) => ({
        url: `v2/notification/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Notification"]
    }),
  })
})


export const {
  useListNotificationsQuery,
  useGetNotificationQuery,

  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
  useCreateNotificationMutation
} = notificationApi
