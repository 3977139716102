
import { apiSlice } from '../api/apiSlice'

export const adminApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    getStatsByKind: builder.query ({
        query: (kind) => `v2/stats/${kind}`,
        keepUnusedDataFor: 0
        })
    })
})

export const {
    useGetStatsByKindQuery
} = adminApi
