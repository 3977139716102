
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const questionlistApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listQuestionLists: builder.query ({
      query: (params) => `v2/questionlist/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["QuestionList"]
    }),
    getQuestionList: builder.query ({
      query: (questionlist_id) => `v2/questionlist/${questionlist_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["QuestionList"]
    }),
    getQuestionListQuestions: builder.query ({
      query: (questionlist_id) => `v2/question/?parent=${questionlist_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Question"]
    }),
    getQuestionListDocuments: builder.query ({
      query: (questionlist_id) => `v2/document/?questionlist=${questionlist_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Document"]
    }),
    updateQuestionList: builder.mutation ({
      query: ({questionlist_id, data}) => ({
        url: `v2/questionlist/${questionlist_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["QuestionList"]
    }),
    deleteQuestionList: builder.mutation ({
      query: (questionlist_id) => ({
        url: `v2/questionlist/${questionlist_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["QuestionList"]
    }),
    createQuestionList: builder.mutation ({
      query: (data) => ({
        url: `v2/questionlist/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["QuestionList"]
    }),
  })
})


export const {
  useListQuestionListsQuery,
  useGetQuestionListQuery,
  useGetQuestionListQuestionsQuery,
  useGetQuestionListDocumentsQuery,
  useUpdateQuestionListMutation,
  useDeleteQuestionListMutation,
  useCreateQuestionListMutation
} = questionlistApi
