
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const permissionApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listPermissions: builder.query ({
      query: (params) => `v2/permission/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Permission"]
    }),
    getPermission: builder.query ({
      query: (permission_id) => `v2/permission/${permission_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Permission"]
    }),
    getPermissionPersons: builder.query ({
      query: (permission_id) => `v2/personpermission/?permission=${permission_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["PersonPermission"]
    }),
    updatePermission: builder.mutation ({
      query: ({permission_id, data}) => ({
        url: `v2/permission/${permission_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Permission"]
    }),
    deletePermission: builder.mutation ({
      query: (permission_id) => ({
        url: `v2/permission/${permission_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Permission"]
    }),
    createPermission: builder.mutation ({
      query: (data) => ({
        url: `v2/permission/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Permission"]
    }),
  })
})


export const {
  useListPermissionsQuery,
  useGetPermissionQuery,
  useGetPermissionPersonsQuery,
  useUpdatePermissionMutation,
  useDeletePermissionMutation,
  useCreatePermissionMutation
} = permissionApi
