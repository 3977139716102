import React from 'react';
import { Routes, Route} from "react-router-dom";
import { ThemeProvider } from '@emotion/react';
import { Container, CssBaseline } from '@mui/material';
import GetTheme from "./theme/Theme";

import { Home } from "./views/Home";
import { LoginScreen } from "./views/Login";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { RequireAuth, RequireAdminAuth } from './components/login/requireAuth';
import { Admin } from './views/admin/Admin';
import { FeedAdminView } from './views/admin/Feed';
import { CompanyAdminView } from './views/admin/Companies';

function App() {
  return <ThemeProvider theme={GetTheme()}>
    <CssBaseline />
    <Header />
    <Container maxWidth="md">
    <Routes>
    <Route element={<RequireAuth />}>
      <Route index element={<Home />} />
      <Route element={<RequireAdminAuth />}>
        <Route path={"/admin"} element={<Admin />} />
        <Route path={"/admin/feed"} element={<FeedAdminView />} />
        <Route path={"/admin/company"} element={<CompanyAdminView />} />
      </Route>
    </Route>
    <Route path={"/login"} element={<LoginScreen />} />
    </Routes>
    </Container>
    <Footer />
    </ThemeProvider>
}

export default App;
