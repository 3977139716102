function personFields() {
    return [
        {"default": "", "name": "name", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 10},
        {"default": "", "name": "email", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 20},
        {"name": "password", "nullable": true, "server_type": "VARCHAR", "type": "str", "uitype": "password", "z": 30},
        {"default": "#now", "name": "created", "nullable": false, "server_type": "DATETIME", "type": "datetime"},
        {"default": "active", "name": "kind", "nullable": false, "options": ["recruiter", "applicant", "admin"], "server_type": "VARCHAR", "type": "str", "uitype": "choicebox", "z": 60},
        {"default": "{}", "name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str", "z": 100},
        {"backref": "people", "class": "Company", "display": "name", "name": "company", "nullable": true, "server_type": "TEXT", "type": "id"}
    ];
}

function tokenFields() {
    return [
        {"backref": "tokens", "class": "Person", "name": "owner", "nullable": false, "type": "id"},
        {"default": "active", "name": "kind", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 10},
        {"default": "", "name": "token", "nullable": false, "server_type": "TEXT", "type": "str", "z": 15},
        {"default": "#now", "name": "created", "nullable": false, "server_type": "DATETIME", "type": "datetime", "z": 20},
        {"name": "expiration", "nullable": true, "server_type": "DATETIME", "type": "datetime", "z": 30},
        {"default": "active", "name": "status", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 40},
        {"default": "{}", "name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str", "z": 100}
    ];
}

function jobFields() {
    return [
        {"default": "", "name": "title", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 10},
        {"default": "new", "name": "status", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 20},
        {"backref": "jobs", "class": "Person", "name": "owner", "nullable": false, "type": "id"},
        {"default": "{}", "name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str", "z": 30},
        {"name": "created", "nullable": true, "server_type": "DATETIME", "type": "datetime"},
        {"name": "notes", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"backref": "jobs", "class": "Company", "display": "name", "name": "client", "nullable": true, "server_type": "TEXT", "type": "id"},
        {"name": "algo", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"name": "expiration", "nullable": true, "server_type": "DATETIME", "type": "datetime"}
    ];
}

function resumeFields() {
    return [
        {"backref": "resumes", "class": "Person", "name": "owner", "nullable": false, "type": "id"},
        {"default": "#now", "name": "created", "nullable": false, "server_type": "DATETIME", "type": "datetime", "z": 20},
        {"default": "", "name": "status", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 30},
        {"default": "{}", "name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str", "z": 100},
        {"default": "", "name": "name", "nullable": false, "server_type": "TEXT", "type": "str"}
    ];
}

function documentFields() {
    return [
        {"default": "#now", "name": "created", "nullable": false, "server_type": "DATETIME", "type": "datetime", "z": 10},
        {"default": "", "name": "status", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 20},
        {"default": "", "name": "uuid", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 30},
        {"default": "{}", "name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str", "z": 100},
        {"backref": "documents", "class": "Resume", "display": "id", "name": "resume", "nullable": true, "server_type": "TEXT", "type": "id"},
        {"backref": "documents", "class": "Job", "display": "title", "name": "job", "nullable": true, "server_type": "TEXT", "type": "id"},
        {"name": "role", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"name": "filename", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"backref": "documents", "class": "QuestionList", "display": "name", "name": "questionlist", "nullable": true, "server_type": "TEXT", "type": "id"}
    ];
}

function questionFields() {
    return [
        {"backref": "questions", "class": "QuestionList", "name": "parent", "nullable": false, "type": "id"},
        {"default": "", "name": "title", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 10},
        {"default": "{}", "name": "text", "nullable": false, "server_type": "TEXT", "type": "str", "z": 20},
        {"default": "#now", "name": "created", "nullable": false, "server_type": "DATETIME", "type": "datetime", "z": 30},
        {"default": "", "name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str", "z": 100},
        {"name": "order", "nullable": false, "server_type": "FLOAT", "type": "float"}
    ];
}

function matchFields() {
    return [
        {"backref": "matches", "class": "Job", "name": "job", "nullable": false, "type": "id"},
        {"backref": "matches", "class": "Resume", "name": "resume", "nullable": false, "type": "id"},
        {"default": "made", "name": "status", "nullable": false, "options": ["made", "confirmed", "notified"], "server_type": "VARCHAR", "type": "str", "z": 10},
        {"default": "#now", "name": "matched", "nullable": false, "server_type": "DATETIME", "type": "datetime", "z": 30},
        {"default": 0, "name": "score", "nullable": false, "server_type": "FLOAT", "type": "float", "z": 40},
        {"default": "{}", "name": "hits", "nullable": false, "server_type": "TEXT", "type": "str", "z": 50},
        {"default": "{}", "name": "expertise", "nullable": false, "server_type": "TEXT", "type": "str", "z": 60},
        {"default": "{}", "name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str", "z": 100}
    ];
}

function actionFields() {
    return [
        {"backref": "actions", "class": "Job", "name": "job", "nullable": true, "type": "id"},
        {"backref": "actions", "class": "Resume", "name": "resume", "nullable": true, "type": "id"},
        {"backref": "actions", "class": "Match", "name": "match", "nullable": true, "type": "id"},
        {"backref": "actions", "class": "Person", "name": "person", "nullable": true, "type": "id"},
        {"backref": "actions", "class": "Person", "name": "actor", "nullable": true, "type": "id"},
        {"default": "", "name": "action", "nullable": false, "server_type": "VARCHAR", "type": "str", "z": 10},
        {"default": "{}", "name": "data", "nullable": false, "server_type": "TEXT", "type": "str", "z": 20},
        {"default": "#now", "name": "created", "nullable": false, "server_type": "DATETIME", "type": "datetime", "z": 30},
        {"default": "{}", "name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str", "z": 100}
    ];
}

function questionlistFields() {
    return [
        {"name": "name", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"backref": "questionlists", "class": "Person", "name": "owner", "nullable": true, "server_type": "TEXT", "type": "id"},
        {"name": "status", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"default": "normal", "name": "role", "nullable": false, "server_type": "TEXT", "type": "str"}
    ];
}

function jobquestionlistFields() {
    return [
        {"backref": "questionlists", "class": "Job", "display": "name", "name": "job", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"backref": "jobs", "class": "QuestionList", "display": "name", "name": "questionlist", "nullable": false, "server_type": "TEXT", "type": "id"}
    ];
}

function answerFields() {
    return [
        {"backref": "answers", "class": "Person", "display": "name", "name": "person", "nullable": true, "server_type": "TEXT", "type": "id"},
        {"backref": "answers", "class": "Question", "display": "title", "name": "question", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"name": "content", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"default": "#now", "name": "created", "nullable": false, "server_type": "DATETIME", "type": "datetime"}
    ];
}

function permissionFields() {
    return [
        {"name": "name", "nullable": false, "server_type": "TEXT", "type": "str"}
    ];
}

function personpermissionFields() {
    return [
        {"backref": "permissions", "class": "Person", "display": "name", "name": "person", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"backref": "persons", "class": "Permission", "display": "name", "name": "permission", "nullable": true, "server_type": "TEXT", "type": "id"}
    ];
}

function companyFields() {
    return [
        {"column": true, "name": "name", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"column": true, "name": "domain", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"name": "policies", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"name": "metadata", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"name": "address", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"backref": "clients", "class": "Company", "display": "name", "name": "recruiter", "nullable": true, "server_type": "TEXT", "type": "id"}
    ];
}

function companycontactFields() {
    return [
        {"backref": "ascontact", "class": "Person", "display": "name", "name": "person", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"backref": "contacts", "class": "Company", "display": "name", "name": "company", "nullable": true, "server_type": "TEXT", "type": "id"},
        {"name": "role", "nullable": false, "server_type": "TEXT", "type": "str"}
    ];
}

function skillFields() {
    return [
        {"name": "name", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"name": "description", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"name": "lightcast_id", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"name": "kind", "nullable": true, "server_type": "TEXT", "type": "str"}
    ];
}

function resumeskillFields() {
    return [
        {"backref": "skills", "class": "Resume", "display": "", "name": "resume", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"backref": "resumes", "class": "Skill", "name": "skill", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"name": "experience", "nullable": true, "server_type": "INT", "type": "int"},
        {"name": "certification", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"name": "category", "nullable": true, "server_type": "VARCHAR", "type": "str"}
    ];
}

function jobrequirementFields() {
    return [
        {"backref": "requirements", "class": "Job", "name": "job", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"backref": "jobs", "class": "Skill", "name": "skill", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"name": "experience", "nullable": true, "server_type": "INT", "type": "int"},
        {"name": "certification", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"name": "category", "nullable": true, "server_type": "VARCHAR", "type": "str"}
    ];
}

function notificationFields() {
    return [
        {"default": "#now", "name": "timestamp", "nullable": false, "server_type": "DATETIME", "type": "datetime"},
        {"default": "new", "name": "status", "nullable": false, "server_type": "VARCHAR", "type": "str"},
        {"class": "Action", "display": "action", "name": "action", "nullable": true, "server_type": "TEXT", "type": "id"},
        {"name": "subject", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"name": "text", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"default": "{}", "name": "metadata", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"backref": "notifications", "class": "Person", "display": "name", "name": "person", "nullable": false, "server_type": "TEXT", "type": "id"}
    ];
}

function badgeFields() {
    return [
        {"name": "name", "nullable": false, "server_type": "TEXT", "type": "str"},
        {"name": "description", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"name": "category", "nullable": false, "server_type": "VARCHAR", "type": "str"},
        {"default": "{}", "name": "metadata", "nullable": true, "server_type": "TEXT", "type": "str"}
    ];
}

function personbadgeFields() {
    return [
        {"backref": "badges", "class": "Person", "display": "name", "name": "person", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"backref": "persons", "class": "Badge", "display": "name", "name": "badge", "nullable": false, "server_type": "TEXT", "type": "id"},
        {"default": "#now", "name": "earned", "nullable": false, "server_type": "DATETIME", "type": "datetime"}
    ];
}

function feedFields() {
    return [
        {"column": true, "default": "", "name": "title", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"column": true, "default": "users", "name": "scope", "nullable": false, "server_type": "VARCHAR", "type": "str"},
        {"column": false, "name": "content", "nullable": true, "server_type": "TEXT", "type": "str", "uitype": "markdown"},
        {"column": true, "default": "#now", "name": "created", "nullable": true, "server_type": "TEXT", "type": "datetime"},
        {"name": "expires", "nullable": true, "server_type": "DATETIME", "type": "datetime"},
        {"name": "media", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"name": "icon", "nullable": true, "server_type": "VARCHAR", "type": "str"},
        {"name": "color", "nullable": true, "server_type": "VARCHAR", "type": "str"},
        {"name": "url", "nullable": true, "server_type": "TEXT", "type": "str"},
        {"default": "{}", "name": "metadata", "nullable": true, "server_type": "TEXT", "type": "str"}
    ];
}

export {personFields, tokenFields, jobFields, resumeFields, documentFields, questionFields, matchFields, actionFields, questionlistFields, jobquestionlistFields, answerFields, permissionFields, personpermissionFields, companyFields, companycontactFields, skillFields, resumeskillFields, jobrequirementFields, notificationFields, badgeFields, personbadgeFields, feedFields}
