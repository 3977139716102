import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Loading from "../components/Loading";
import { useListFeedsQuery } from "../features/model/feedSlice";
import Markdown from 'react-markdown'
import { useGetNamedFeedQuery } from "../features/pollingSlice";
import { getConfig, setConfig } from "../environment/config";
import { useState } from "react";

const FeedView = ({feed}) => {
    const feedQuery = useListFeedsQuery();
    if(feedQuery.isLoading) return <Loading />;
    return <>{feedQuery.data.map((f, idx) => {
        return <Card key={`feed_${idx}`} variant="outlined">
        <CardHeader title={f.title} />
        <CardContent>
            <Markdown>
                {f.content}
            </Markdown>
        </CardContent>
        </Card>
    })}</>;
}

const NamedFeedView = ({name, title="", onDismiss=null}) => {
    const feedQuery = useGetNamedFeedQuery(name);
    if(feedQuery.isLoading) return <Loading />;
    if(!feedQuery.data || feedQuery.isError) return <></>;
    if(Object.keys(feedQuery.data).length === 0) return <></>;
    return <Card key={`feed_${name}`} variant="outlined">
        {title !== "" ? <CardHeader title={title} /> : <></>}
        <CardContent>
            <Markdown>
                {feedQuery.data.content}
            </Markdown>
        </CardContent>
        {onDismiss !== null ? 
        <CardActions>
            <Button onClick={onDismiss}>Dismiss</Button>
        </CardActions>
        : <></>}
    </Card>;
}

const WelcomeView = () => {
    const [dismissedWelcome, setDismissedWelcome] = useState(getConfig("welcome_dismissed") || false);
    if(dismissedWelcome) {
        return <></>;
    }
    return <NamedFeedView name="_welcome" onDismiss={() => {
        setConfig("welcome_dismissed", true);
        setDismissedWelcome(true);
    }} />;
}

export { NamedFeedView, WelcomeView, FeedView }