
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const documentApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listDocuments: builder.query ({
      query: (params) => `v2/document/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Document"]
    }),
    getDocument: builder.query ({
      query: (document_id) => `v2/document/${document_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Document"]
    }),

    updateDocument: builder.mutation ({
      query: ({document_id, data}) => ({
        url: `v2/document/${document_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Document"]
    }),
    deleteDocument: builder.mutation ({
      query: (document_id) => ({
        url: `v2/document/${document_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Document"]
    }),
    createDocument: builder.mutation ({
      query: (data) => ({
        url: `v2/document/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Document"]
    }),
  })
})


export const {
  useListDocumentsQuery,
  useGetDocumentQuery,

  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
  useCreateDocumentMutation
} = documentApi
