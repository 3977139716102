import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        appleLogin: builder.mutation({
            query: credentials => ({
            url: '/apple_login',
                method: 'POST',
                body: credentials
            })
        }),
        googleLogin: builder.mutation({
            query: credentials => ({
            url: '/google_login',
                method: 'POST',
                body: credentials
            })
        })

    }),
    overrideExisting: true
})

export const { useAppleLoginMutation, useGoogleLoginMutation } = authApiSlice
