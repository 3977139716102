
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const matchApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listMatchs: builder.query ({
      query: (params) => `v2/match/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Match"]
    }),
    getMatch: builder.query ({
      query: (match_id) => `v2/match/${match_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Match"]
    }),
    getMatchActions: builder.query ({
      query: (match_id) => `v2/action/?match=${match_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Action"]
    }),
    updateMatch: builder.mutation ({
      query: ({match_id, data}) => ({
        url: `v2/match/${match_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Match"]
    }),
    deleteMatch: builder.mutation ({
      query: (match_id) => ({
        url: `v2/match/${match_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Match"]
    }),
    createMatch: builder.mutation ({
      query: (data) => ({
        url: `v2/match/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Match"]
    }),
  })
})


export const {
  useListMatchsQuery,
  useGetMatchQuery,
  useGetMatchActionsQuery,
  useUpdateMatchMutation,
  useDeleteMatchMutation,
  useCreateMatchMutation
} = matchApi
