import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './apiSlice';
import authReducer from './auth/authSlice'
import { API_URL } from '../environment/vars';
import { pollingApi } from '../features/pollingSlice';

// Redux Slicer generated paths
// RSGP
import { personApi } from '../features/model/personSlice';
import { tokenApi } from '../features/model/tokenSlice';
import { jobApi } from '../features/model/jobSlice';
import { resumeApi } from '../features/model/resumeSlice';
import { documentApi } from '../features/model/documentSlice';
import { questionApi } from '../features/model/questionSlice';
import { matchApi } from '../features/model/matchSlice';
import { actionApi } from '../features/model/actionSlice';
import { questionlistApi } from '../features/model/questionlistSlice';
import { jobquestionlistApi } from '../features/model/jobquestionlistSlice';
import { answerApi } from '../features/model/answerSlice';
import { permissionApi } from '../features/model/permissionSlice';
import { personpermissionApi } from '../features/model/personpermissionSlice';
import { companyApi } from '../features/model/companySlice';
import { companycontactApi } from '../features/model/companycontactSlice';
import { skillApi } from '../features/model/skillSlice';
import { resumeskillApi } from '../features/model/resumeskillSlice';
import { jobrequirementApi } from '../features/model/jobrequirementSlice';
import { notificationApi } from '../features/model/notificationSlice';
import { badgeApi } from '../features/model/badgeSlice';
import { personbadgeApi } from '../features/model/personbadgeSlice';
import { feedApi } from '../features/model/feedSlice';
// END_RSGP
const store = configureStore({
    reducer: {
// Redux Slicer generated reductions
// RSGR
    [personApi.reducerPath]: personApi.reducer,
    [tokenApi.reducerPath]: tokenApi.reducer,
    [jobApi.reducerPath]: jobApi.reducer,
    [resumeApi.reducerPath]: resumeApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [questionApi.reducerPath]: questionApi.reducer,
    [matchApi.reducerPath]: matchApi.reducer,
    [actionApi.reducerPath]: actionApi.reducer,
    [questionlistApi.reducerPath]: questionlistApi.reducer,
    [jobquestionlistApi.reducerPath]: jobquestionlistApi.reducer,
    [answerApi.reducerPath]: answerApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [personpermissionApi.reducerPath]: personpermissionApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [companycontactApi.reducerPath]: companycontactApi.reducer,
    [skillApi.reducerPath]: skillApi.reducer,
    [resumeskillApi.reducerPath]: resumeskillApi.reducer,
    [jobrequirementApi.reducerPath]: jobrequirementApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [badgeApi.reducerPath]: badgeApi.reducer,
    [personbadgeApi.reducerPath]: personbadgeApi.reducer,
    [feedApi.reducerPath]: feedApi.reducer,
// END_RSGR
    [pollingApi.reducerPath]: pollingApi.reducer,
    auth: authReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true,
    baseUrl: API_URL
});

export {store};
