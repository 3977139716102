
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const badgeApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listBadges: builder.query ({
      query: (params) => `v2/badge/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Badge"]
    }),
    getBadge: builder.query ({
      query: (badge_id) => `v2/badge/${badge_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Badge"]
    }),
    getBadgePersons: builder.query ({
      query: (badge_id) => `v2/personbadge/?badge=${badge_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["PersonBadge"]
    }),
    updateBadge: builder.mutation ({
      query: ({badge_id, data}) => ({
        url: `v2/badge/${badge_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Badge"]
    }),
    deleteBadge: builder.mutation ({
      query: (badge_id) => ({
        url: `v2/badge/${badge_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Badge"]
    }),
    createBadge: builder.mutation ({
      query: (data) => ({
        url: `v2/badge/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Badge"]
    }),
  })
})


export const {
  useListBadgesQuery,
  useGetBadgeQuery,
  useGetBadgePersonsQuery,
  useUpdateBadgeMutation,
  useDeleteBadgeMutation,
  useCreateBadgeMutation
} = badgeApi
