
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const feedApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listFeeds: builder.query ({
      query: (params) => `v2/feed/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Feed"]
    }),
    getFeed: builder.query ({
      query: (feed_id) => `v2/feed/${feed_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Feed"]
    }),

    updateFeed: builder.mutation ({
      query: ({feed_id, data}) => ({
        url: `v2/feed/${feed_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Feed"]
    }),
    deleteFeed: builder.mutation ({
      query: (feed_id) => ({
        url: `v2/feed/${feed_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Feed"]
    }),
    createFeed: builder.mutation ({
      query: (data) => ({
        url: `v2/feed/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Feed"]
    }),
  })
})


export const {
  useListFeedsQuery,
  useGetFeedQuery,

  useUpdateFeedMutation,
  useDeleteFeedMutation,
  useCreateFeedMutation
} = feedApi
