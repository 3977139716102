
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const jobApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listJobs: builder.query ({
      query: (params) => `v2/job/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Job"]
    }),
    getJob: builder.query ({
      query: (job_id) => `v2/job/${job_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Job"]
    }),
    getJobActions: builder.query ({
      query: (job_id) => `v2/action/?job=${job_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Action"]
    }),
    getJobMatches: builder.query ({
      query: (job_id) => `v2/match/?job=${job_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Match"]
    }),
    getJobQuestionlists: builder.query ({
      query: (job_id) => `v2/jobquestionlist/?job=${job_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["JobQuestionList"]
    }),
    getJobDocuments: builder.query ({
      query: (job_id) => `v2/document/?job=${job_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Document"]
    }),
    getJobRequirements: builder.query ({
      query: (job_id) => `v2/jobrequirement/?job=${job_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["JobRequirement"]
    }),
    updateJob: builder.mutation ({
      query: ({job_id, data}) => ({
        url: `v2/job/${job_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Job"]
    }),
    deleteJob: builder.mutation ({
      query: (job_id) => ({
        url: `v2/job/${job_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Job"]
    }),
    createJob: builder.mutation ({
      query: (data) => ({
        url: `v2/job/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Job"]
    }),
  })
})


export const {
  useListJobsQuery,
  useGetJobQuery,
  useGetJobActionsQuery,
  useGetJobMatchesQuery,
  useGetJobQuestionlistsQuery,
  useGetJobDocumentsQuery,
  useGetJobRequirementsQuery,
  useUpdateJobMutation,
  useDeleteJobMutation,
  useCreateJobMutation
} = jobApi
