import { Box, Grid, Stack, TextField, Typography } from "@mui/material";

const MetaViewField = ({field, data, setValue=null, edit=false}) => {
    let value = "";
    if(data && field.key in data) {
        value = `${data[field.key]}`;
    }
    return <Grid container spacing={2} alignItems="center">
        <Grid item xs={3} alignItems="center">
        <Typography>{field.label}</Typography>
        </Grid>
        <Grid item xs={7} alignItems="center">
        { edit ?
            <TextField variant="outlined" size="small" defaultValue={value} onChange={event => {
                if(setValue) {
                    setValue(field.key, event.target.value);
                }
            }} />
            :
            <Typography color={value === "" ? "#dddddd" : "#000000"}>{value !== "" ? value : "not entered"}</Typography>
        }
        </Grid>
    </Grid>;
}

const MetaViewer = ({spec, data, setValue=null, edit=false}) => {
    return <Box>
        {spec.fields.map((field) => {
            return <MetaViewField key={`field_${field.key}`} field={field} data={data} edit={edit} setValue={setValue}/>;
        })}
    </Box>;
}

export { MetaViewer };
