
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const resumeskillApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listResumeSkills: builder.query ({
      query: (params) => `v2/resumeskill/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["ResumeSkill"]
    }),
    getResumeSkill: builder.query ({
      query: (resumeskill_id) => `v2/resumeskill/${resumeskill_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["ResumeSkill"]
    }),

    updateResumeSkill: builder.mutation ({
      query: ({resumeskill_id, data}) => ({
        url: `v2/resumeskill/${resumeskill_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["ResumeSkill"]
    }),
    deleteResumeSkill: builder.mutation ({
      query: (resumeskill_id) => ({
        url: `v2/resumeskill/${resumeskill_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["ResumeSkill"]
    }),
    createResumeSkill: builder.mutation ({
      query: (data) => ({
        url: `v2/resumeskill/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["ResumeSkill"]
    }),
  })
})


export const {
  useListResumeSkillsQuery,
  useGetResumeSkillQuery,

  useUpdateResumeSkillMutation,
  useDeleteResumeSkillMutation,
  useCreateResumeSkillMutation
} = resumeskillApi
