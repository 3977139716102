import { createSlice } from "@reduxjs/toolkit";
import { deleteConfig, getConfig, setConfig } from "../../environment/config";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        access_token: getConfig("auth_access_token")
    },
    reducers: {
        setToken: (state, action) => {
            state.access_token = action.payload;
            if(action.payload) {
                setConfig("auth_access_token", action.payload);
            }
        },
        logOut: (state, action) => {
            state.access_token = null;
            deleteConfig("auth_access_token");
        }
    }
})

export const {setToken, logOut} = authSlice.actions

export default authSlice.reducer
