import { useLayoutEffect, useState } from "react";
import { ErrorView } from "../components/Error";
import { useGetHomeQuery, useGetPollingQuery } from "../features/pollingSlice";
import { MetaViewer } from "../components/MetaEditor";
import { useUpdatePersonMutation } from "../features/model/personSlice";
import { RoundButton } from "../components/RoundButton";
import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Loading from "../components/Loading";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

function CurrentUserCard(props) {
  const [showEdit, setShowEdit] = useState(false);
  const userData = props.userData;
  const [metadata, setMetadata] = useState(JSON.parse(userData.metadata).info);
  const [updatePersonMutation] = useUpdatePersonMutation()

  var userSpec = {
    fields: [
      { key: "name", label: "Name", icon: "user" },
      { key: "email", label: "Email", icon: "envelope" },
      { key: "phone", label: "Phone #", icon: "mobile-phone" }
    ]
  }

  if(userData.permissions.includes("recruit")) {
    userSpec.fields.push({ key: "company", label: "Company", icon: "user" })
    userSpec.fields.push({ key: "location", label: "Location", icon: "user" })
  }

  function setValue(key, value) {
    setMetadata({...metadata, [key]: value});
  }

  const saveMeta = () => {
    updatePersonMutation({person_id: userData.id, data: {info: metadata}});
    setShowEdit(false);
  }

  return <Card variant="outlined">
    <CardHeader title="Profile" action={
      !showEdit ?
        <RoundButton icon={<EditIcon />} mode="contained" onPress={() => {setShowEdit(true)}} />
        :
        <RoundButton icon={<SaveIcon />} mode="contained" onPress={saveMeta} />
    }/>
    <CardContent>
      <MetaViewer spec={userSpec} data={metadata} edit={showEdit} setValue={setValue} />
    </CardContent>
    {showEdit ? 
      <CardActions>
        <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => {
          setMetadata(JSON.parse(userData.metadata).info);
          setShowEdit(false);
        }}>Cancel</Button>
        <Button variant="contained" startIcon={<SaveIcon />} onClick={saveMeta}>Save</Button>
      </CardActions>
      : <></>}
  </Card>;
}

function ProfileView() {
  const person = useGetHomeQuery();
  const [userName, setUserName] = useState("Loading details...");

  if(person.isError) {
    return <ErrorView query={person} />;
  }

  if(!person.data) {
    return <Loading />;
  }

  if(userName !== person.data.name)
    setUserName(person.data.name);

  return <CurrentUserCard userData={person.data} />;
}

export { ProfileView };
