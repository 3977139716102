
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const skillApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listSkills: builder.query ({
      query: (params) => `v2/skill/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Skill"]
    }),
    getSkill: builder.query ({
      query: (skill_id) => `v2/skill/${skill_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Skill"]
    }),
    getSkillResumes: builder.query ({
      query: (skill_id) => `v2/resumeskill/?skill=${skill_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["ResumeSkill"]
    }),
    getSkillJobs: builder.query ({
      query: (skill_id) => `v2/jobrequirement/?skill=${skill_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["JobRequirement"]
    }),
    updateSkill: builder.mutation ({
      query: ({skill_id, data}) => ({
        url: `v2/skill/${skill_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Skill"]
    }),
    deleteSkill: builder.mutation ({
      query: (skill_id) => ({
        url: `v2/skill/${skill_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Skill"]
    }),
    createSkill: builder.mutation ({
      query: (data) => ({
        url: `v2/skill/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Skill"]
    }),
  })
})


export const {
  useListSkillsQuery,
  useGetSkillQuery,
  useGetSkillResumesQuery,
  useGetSkillJobsQuery,
  useUpdateSkillMutation,
  useDeleteSkillMutation,
  useCreateSkillMutation
} = skillApi
