import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, hexToRgb, responsiveFontSizes } from '@mui/material/styles';

const GetTheme = () => {
    //const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)");
    return responsiveFontSizes(createTheme({
        typography: {
            fontFamily: 'Helvetica, sans-serif, Arial',
            h1: {
                fontSize: 40,
                fontWeight: "bold",
            },
            h2: {
                fontSize: 20,
                fontWeight: "bold",
            },
            h3: {
                fontSize: 15,
                fontWeight: "bold",
            },
            h4: {
                fontSize: 13,
                fontWeight: "bold",
            },
            div: {
                fontSize: 12,
                fontWeight: "light",
            }
        },
        palette: {
            primary: {
                main: '#227cff',
            },
            secondary: {
                main: '#ff3f0f',
            },
            mode: 'light',
            background: {
                default: '#eeeeee'
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 8
                    }
                }
            },
            MuiCssBaseline: {
                styleOverrides: {
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#ffffff",
                        borderRadius: "12px"
                    }
                }
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        paddingBottom: 0
                    }
                }
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        paddingTop: 8,
                        paddingBottom: 4
                    }
                }
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        marginLeft: 8,
                        paddingTop: 8,
                        paddingBottom: 4
                    }
                }
            },
            MuiGrid: {
                styleOverrides: {
                    root: {
                        textDecoration: 'none',
                        fontFamily: 'Helvetica, sans-serif, Arial',
                    },
                },
            },
        },
  }));
}

export default GetTheme;
