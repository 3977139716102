import { getConfig } from "../environment/config";
import { GoogleLoginButton } from "../components/login/googleLogin";
import { AppleLoginButton } from "../components/login/appleLogin";
import { DevelopmentLoginButtons } from "../components/login/developmentLogin";
import { Card, CardContent, Paper, Stack } from "@mui/material";
import splash from "../images/splash.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NamedFeedView } from "./FeedView";

function LoginScreen() {
    const token = getConfig("auth_access_token", null);
    const navigate = useNavigate()

    useEffect(() => {
      if (token) {
        if (token !== "") {
            
        }
    };
    }, [])
    
    const onLoginSuccess = () => {
        navigate("/")
    }
    let devlogins = null;
    console.log(process.env);
    if(process.env.REACT_APP_ENABLE_DEV_LOGINS === "1") {
        devlogins = <DevelopmentLoginButtons onSuccess={onLoginSuccess} />;
    }
    
    return <Stack direction="row" justifyContent={"center"} alignItems="center" sx={{width: 1, height: "100vh"}}>
            <Paper sx={{backgroundColor: "#eeeeee", borderRadius: "10px"}}><Card variant="outlined" sx={{borderRadius: "10px"}}>
                <CardContent>
                <Stack direction={"column"} sx={{alignSelf: "center", alignItems: "center", gap: 2, margin: 2}}>
                    <img src={splash} width={128} height={128} />
                    <GoogleLoginButton onSuccess={onLoginSuccess} />
                    <AppleLoginButton onSuccess={onLoginSuccess} />
                    <NamedFeedView name="_login" />
                    {devlogins}
                </Stack>
                </CardContent>
        </Card></Paper>
    </Stack>;
}

export { LoginScreen };