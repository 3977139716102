import Cookies from 'js-cookie';

function setConfig(key, value) {
    Cookies.set(key, value, { path: '' });
}

function getConfig(key) {
    return Cookies.get(key, { path: '' });
}

function deleteConfig(key) {
    Cookies.remove(key, { path: '' });
}

export {setConfig, getConfig, deleteConfig}