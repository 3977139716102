
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const actionApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listActions: builder.query ({
      query: (params) => `v2/action/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Action"]
    }),
    getAction: builder.query ({
      query: (action_id) => `v2/action/${action_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Action"]
    }),

    updateAction: builder.mutation ({
      query: ({action_id, data}) => ({
        url: `v2/action/${action_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Action"]
    }),
    deleteAction: builder.mutation ({
      query: (action_id) => ({
        url: `v2/action/${action_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Action"]
    }),
    createAction: builder.mutation ({
      query: (data) => ({
        url: `v2/action/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Action"]
    }),
  })
})


export const {
  useListActionsQuery,
  useGetActionQuery,

  useUpdateActionMutation,
  useDeleteActionMutation,
  useCreateActionMutation
} = actionApi
