import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Uploader = ({onCancel, onUpload, title, disabled=false}) => {
  const [file, setFile] = useState();
  const [fileData, setFileData] = useState(null);

  useEffect(() => {
    if(file && fileData) {
      console.log(`Uploading ${file.name}, ${fileData.length} bytes`);
      onUpload(file, fileData.split("base64,")[1]);
      setFileData(null);
    }
  }, [file, fileData, onUpload]);

  return (
      <Stack direction="column" spacing={1}>
          <Typography>{title} </Typography>
          <input type="file" onChange={(e) => {
                setFile(e.target.files[0]);
                const reader = new FileReader();
                reader.onload = () => {
                    const binaryStr = reader.result;
                    setFileData(binaryStr);
                };
                reader.readAsDataURL(e.target.files[0]);
            }} />
      </Stack>
  );
};

export default Uploader;
