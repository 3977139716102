import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { useGetHomeQuery } from "../../features/pollingSlice";

const getUser = (state) => state.auth.access_token;

const RequireAuth = () => {
    const user = useSelector(getUser);
    const location = useLocation();
    return (
        user
            ? <Outlet />
            : <Navigate to={`/login`} state={{ from: location }} replace />
    )
}

const RequireAdminAuth = () => {
    const query = useGetHomeQuery();
    const location = useLocation();
    if(query.isLoading) return <></>;
    const user = query.data;
    if(user) {
        if(user.permissions) {
            if(user.permissions.includes("admin")) {
                return <Outlet />;
            }
        }
    }
    console.log("Sending you back");
    return <Navigate to={`/`} state={{ from: location }} replace />;
}

export { RequireAuth, RequireAdminAuth };