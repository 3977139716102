import {useListCompaniesQuery, useCreateCompanyMutation, useDeleteCompanyMutation, useUpdateCompanyMutation} from "../../features/model/companySlice";
import { RESTList } from "../rest/List";
import { companyFields } from "../../features/model/modelFields";
const CompanyRESTView = () => {
    const companyQuery = useListCompaniesQuery({all: true});
    const addMutation = useCreateCompanyMutation();
    const deleteMutation = useDeleteCompanyMutation();
    const updateMutation = useUpdateCompanyMutation();
    return <RESTList
        query={companyQuery}
        spec={companyFields()}
        objectType={"company"}
        addMutation={addMutation}
        deleteMutation={deleteMutation}
        updateMutation={updateMutation}
    />;
}
export { CompanyRESTView };