import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { FeedRESTView } from '../components/model/Feed';
import { useGetHomeQuery } from '../features/pollingSlice';
import Loading from '../components/Loading';
import { FeedView, WelcomeView } from './FeedView';
import { ResumesView } from './ResumesView';
import { ProfileView } from './Profile';

const Home = () => {
    const query = useGetHomeQuery();
    if(query.isLoading) {
        return <Loading />;
    }
    return <Stack direction="column" sx={{mt: 4, alignSelf: "center"}} spacing={4}>
        <WelcomeView />
        <FeedView feed="prerelease" />
        <ProfileView />
        <ResumesView userInfo={query.data} />
    </Stack>;
}

export { Home };
