
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const companyApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listCompanies: builder.query ({
      query: (params) => `v2/company/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Company"]
    }),
    getCompany: builder.query ({
      query: (company_id) => `v2/company/${company_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Company"]
    }),
    getCompanyClients: builder.query ({
      query: (company_id) => `v2/company/?recruiter=${company_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Company"]
    }),
    getCompanyJobs: builder.query ({
      query: (company_id) => `v2/job/?client=${company_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Job"]
    }),
    updateCompany: builder.mutation ({
      query: ({company_id, data}) => ({
        url: `v2/company/${company_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Company"]
    }),
    deleteCompany: builder.mutation ({
      query: (company_id) => ({
        url: `v2/company/${company_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Company"]
    }),
    createCompany: builder.mutation ({
      query: (data) => ({
        url: `v2/company/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Company"]
    }),
  })
})


export const {
  useListCompaniesQuery,
  useGetCompanyQuery,
  useGetCompanyClientsQuery,
  useGetCompanyJobsQuery,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useCreateCompanyMutation
} = companyApi
