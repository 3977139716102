import { setToken } from "../../api/auth/authSlice";
import AppleSignin from 'react-apple-signin-auth';
import { useAppleLoginMutation } from "../../api/auth/authApiSlice";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

function AppleLoginButton({onSuccess}) {
  const [loginCredentials, setLoginCredentials] = useState(null);
  const [login] = useAppleLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    async function performLogin() {
        if (loginCredentials) {
          console.log(loginCredentials);
          const auth_dict = {
            identityToken: loginCredentials.authorization.id_token,
            code: loginCredentials.authorization.code,
            state: loginCredentials.authorization.state
          };
          console.log(auth_dict);
          const response = await login(auth_dict).unwrap();
          dispatch(setToken(response.token))
          onSuccess();
        }
    };
    performLogin();
  }, [loginCredentials]);

  return <AppleSignin
    authOptions={{
      clientId: 'io.unsignedtalent.app.sourceme.web',
      scope: 'email name',
      state: 'smelogin',
      nonce: 'nonce',
      redirectURI: process.env.REACT_APP_HOMEPAGE_URL + "login",
      usePopup: true
    }}
    style={{width: 220, height: 40, borderRadius: 20}}
    buttonExtraChildren={"Log in with Apple"}
    onSuccess={(response) => {
      setLoginCredentials(response);
    }}
    onError={(error) => {
      console.log(error);
    }}
  />
}

export { AppleLoginButton };