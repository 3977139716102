import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(utc);
dayjs.extend(localizedFormat);

function formatUTCDate(date) {
    if(date == null){
        return "";
    }
    return dayjs.utc(date).local().format("YYYY-MM-DD HH:mm");
}

function formatFriendlyUTCDate(date) {
    if(date == null){
        return "";
    }
    return dayjs.utc(date).local().format("ll, LT");
}

function isInPast(date) {
    if(date == null){
        return false;
    }
    return dayjs.utc(date) < dayjs.utc();
}

function formatDaySpan(days) {
    if (!days) {
        return "";
    }
    if (days < 31) {
        if(days == 1) {
            return "1 day";
        }
        return `${days} days`;
    }
    let years = Math.floor(days / 365.2425);
    let months = Math.floor((days - years * 365.2425) / 30.437);
    if(years == 0) {
        return `${months} month${months!=1?"s":""}`;
    }
    return `${years} year${years!=1?"s":""}, ${months} month${months!=1?"s":""}`;
}

export { formatUTCDate, isInPast, formatFriendlyUTCDate, formatDaySpan };