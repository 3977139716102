import {useListFeedsQuery, useCreateFeedMutation, useDeleteFeedMutation, useUpdateFeedMutation} from "../../features/model/feedSlice";
import { RESTList } from "../rest/List";
import { feedFields } from "../../features/model/modelFields";
const FeedRESTView = () => {
    const feedQuery = useListFeedsQuery({all: true});
    const addMutation = useCreateFeedMutation();
    const deleteMutation = useDeleteFeedMutation();
    const updateMutation = useUpdateFeedMutation();
    return <RESTList
        query={feedQuery}
        spec={feedFields()}
        objectType={"feed"}
        addMutation={addMutation}
        deleteMutation={deleteMutation}
        updateMutation={updateMutation}
    />;
}
export { FeedRESTView };