import { Button, Card, CardActions, CardContent, CardHeader, FormControl, FormLabel, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material";

import Loading from "../Loading";
import { useState } from "react";

import MDEditor from '@uiw/react-md-editor';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function capitalized(text) {
    if(text.length < 4) {
        return text.toUpperCase();
    }
    return text.charAt(0).toUpperCase() + text.slice(1).replace("_", " ");
}

const RESTMarkDownEditor = ({field, text, onChange, readOnly=false}) => {
    const [value, setValue] = useState(text);
    function propagateChange(text) {
        if(readOnly) {
            return;
        }
        setValue(text);
        onChange(text);
    }
    return <div data-color-mode="light">
        <div className="wmde-markdown-var"> </div>
        <Stack direction="column" sx={{marginLeft: "1px", marginRight: "1px"}}>
        <FormLabel>{field.label || capitalized(field.name) || "(unknown)"}</FormLabel>
        <MDEditor value={value} onChange={propagateChange} />
        </Stack>
    </div>;
}

function defaultsForSpec(spec) {
    var ret = {};
    spec.forEach(field => {
        if(field.default) {
            ret[field.name] = field.default;
        }
    })
    return ret;
}

const RESTAddEditCard = ({spec, addMutation, updateMutation, update=false, objectType, onClose, currentData=null}) => {
    function convertIncomingData(data) {
        var ret = {...data};
        
        // Convert #now for datetime fields
        spec.forEach((field) => {
            if(data[field.name]) {
                if(field.type === "datetime") {
                    if (data[field.name] === "#now") {
                        ret[field.name] = dayjs()
                    } else if (data[field.name] !== "") {
                        ret[field.name] = dayjs(ret[field.name]);
                    } else {
                        ret[field.name] = null
                    }
                }
            }
        });
        return ret;
    }


    const [data, setData] = useState(currentData ? convertIncomingData(currentData) : defaultsForSpec(spec));
    const [error, setError] = useState(null);

    function convertData(data) {
        var ret = {...data};
        // Convert #now for datetime fields
        spec.forEach((field) => {
            if(data[field.name]) {
                if(field.type === "datetime") {
                    if (data[field.name] === "#now") {
                        ret[field.name] = dayjs().utc()
                    } else if (data[field.name] !== "") {
                        ret[field.name] = dayjs(ret[field.name]).utc();
                    } else {
                        ret[field.name] = null
                    }
                }
            }
        });
        return ret;
    }

    function performAdd() {
        addMutation[0](convertData(data));
    }

    function performUpdate() {
        updateMutation[0]({[`${objectType}_id`]: currentData.id, data: convertData(data)});
    }

    let readOnly = false;

    console.log(data);

    if(addMutation) {
        if(addMutation[1].isLoading) {
            readOnly = true;
        } else if(addMutation[1].isSuccess) {
            onClose();
        } else if (addMutation[1].isError) {
            if (error !== addMutation[1].error.error) {
                setError(addMutation[1].error.error);
            }
        }
    }
    if(updateMutation) {
        if(updateMutation[1].isLoading) {
            readOnly = true;
        } else if(updateMutation[1].isSuccess) {
            onClose();
        } else if (updateMutation[1].isError) {
            if (error !== updateMutation[1].error.error) {
                setError(updateMutation[1].error.error);
            }
        }
    }

    function formField(field) {
        let defValue = data[field.name] || "";
        let label = field.label || capitalized(field.name) || "";
        let required = !(field.nullable || false);
        if((field.type || "str") === "str") {
            if ((field.uitype || "") === "markdown") {
                return <RESTMarkDownEditor readOnly={readOnly} field={field} text={defValue} onChange={(text) => {
                    setData({...data, [field.name]: text});
                }} />;
            }
            return <TextField readOnly={readOnly} required={required} defaultValue={defValue} label={label} onChange={(e) => {
                setData({...data, [field.name]: e.target.value});
            }} />
        } else if (field.type === "datetime") {
            return <DateTimePicker readOnly={readOnly} required={required} label={label} defaultValue={defValue === "" ? null : defValue === "#now" ? dayjs() : defValue} onChange={(e) => {
                setData({...data, [field.name]: dayjs(e).utc()})
            }} />
        }
        return <></>;
    }

    return <Card>
        <CardHeader title={update ? `Update ${objectType}` : `Add new ${objectType}`} />
        <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="column" gap={1}>
        {
            spec.map((field) => <FormControl key={field.name}>
                {formField(field)}
            </FormControl>)
        }
        </Stack>
        </LocalizationProvider>
        </CardContent>
        <CardActions sx={{justifyContent: "flex-end"}}>
            {error ? <Typography sx={{color: "#f00"}}>{error}</Typography> : <></>}
            <Button variant="outlined" onClick={onClose}>Cancel</Button>
            <Button variant="contained" onClick={update ? performUpdate : performAdd}>{update ? "Update" : "Add"}</Button>
        </CardActions>
    </Card>
}

const RESTList = ({query, spec, objectType, addMutation, updateMutation, deleteMutation}) => {
    const [adding, setAdding] = useState(false);
    const [editing, setEditing] = useState(null);

    let headerFields = [];
    let headerKeys = [];
    let specByKey = Object.fromEntries(spec.map(field => [field.name, field]));
    spec.filter((s) => s.column || false).forEach(field => {
        headerFields.push(
            <TableCell key={field.name}>{field.label || capitalized(field.name) || ""}</TableCell>
        );
        headerKeys.push(field.name);
    });
    var contentFields = [];

    function renderValue(field, value) {
        if((field.type || "") === "datetime") {
            return dayjs(value).local().format("D/M/YY HH:mm");
        }
        return `${value}`;
    }
    var editData = {};
    console.log(query);
    if(query.isLoading || !query.data) {
        contentFields = [
            <TableRow key={"loading"}>
                {headerKeys.map((k) => <TableCell key={k}><Loading /></TableCell>)}
                <TableCell key="edit_actions"><Loading /></TableCell>
            </TableRow>
        ];
    } else {
        contentFields = query.data.map((row, idx) => {
            if(row.id === editing) editData = row;
            return <TableRow key={idx}>{
                headerKeys.map((k) => 
                    <TableCell key={k}>{renderValue(specByKey[k], row[k])}</TableCell>
                )}
                <TableCell key="edit_actions">
                    <IconButton key="edit" size="small" onClick={() => {
                        setEditing(row.id);
                    }}><EditIcon /></IconButton>
                    <IconButton key="delete" size="small" onClick={() => {
                        deleteMutation[0](row.id);
                    }}><DeleteIcon /></IconButton>
                </TableCell>
            </TableRow>;
        })
    }

    return adding ?
        <RESTAddEditCard
            spec={spec} addMutation={addMutation} objectType={objectType} onClose={() => {
                setAdding(false);
            }}/>
    : (editing !== null) ?
        <RESTAddEditCard
            spec={spec} updateMutation={updateMutation} objectType={objectType} update={true}
            currentData={editData}
            onClose={() => {
                setEditing(null);
                window.location.reload();
            }}/>
    :
        <Stack direction="column"><TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    {headerFields}
                    <TableCell key="edit_actions">Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {contentFields}
            </TableBody>
            <TableFooter>
            </TableFooter>
        </Table>
        </TableContainer>
        <Stack direction="row">
            <Button onClick={() => setAdding(true)}>Add new...</Button>
        </Stack>
        </Stack>

}

export {RESTList};