
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const personApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listPersons: builder.query ({
      query: (params) => `v2/person/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["Person"]
    }),
    getPerson: builder.query ({
      query: (person_id) => `v2/person/${person_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["Person"]
    }),
    getPersonJobs: builder.query ({
      query: (person_id) => `v2/job/?owner=${person_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Job"]
    }),
    getPersonResumes: builder.query ({
      query: (person_id) => `v2/resume/?owner=${person_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Resume"]
    }),
    getPersonTokens: builder.query ({
      query: (person_id) => `v2/token/?owner=${person_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Token"]
    }),
    getPersonActions: builder.query ({
      query: (person_id) => `v2/action/?person=${person_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Action"]
    }),
    getPersonPerformedactions: builder.query ({
      query: (person_id) => `v2/action/?actor=${person_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Action"]
    }),
    getPersonPermissions: builder.query ({
      query: (person_id) => `v2/personpermission/?person=${person_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["PersonPermission"]
    }),
    getPersonQuestionlists: builder.query ({
      query: (person_id) => `v2/questionlist/?owner=${person_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["QuestionList"]
    }),
    getPersonNotifications: builder.query ({
      query: (person_id) => `v2/notification/?person=${person_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["Notification"]
    }),
    getPersonBadges: builder.query ({
      query: (person_id) => `v2/personbadge/?person=${person_id}`,
      keepUnusedDataFor: 1,
      providesTags: ["PersonBadge"]
    }),
    updatePerson: builder.mutation ({
      query: ({person_id, data}) => ({
        url: `v2/person/${person_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["Person"]
    }),
    deletePerson: builder.mutation ({
      query: (person_id) => ({
        url: `v2/person/${person_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["Person"]
    }),
    createPerson: builder.mutation ({
      query: (data) => ({
        url: `v2/person/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["Person"]
    }),
  })
})


export const {
  useListPersonsQuery,
  useGetPersonQuery,
  useGetPersonJobsQuery,
  useGetPersonResumesQuery,
  useGetPersonTokensQuery,
  useGetPersonActionsQuery,
  useGetPersonPerformedactionsQuery,
  useGetPersonPermissionsQuery,
  useGetPersonQuestionlistsQuery,
  useGetPersonNotificationsQuery,
  useGetPersonBadgesQuery,
  useUpdatePersonMutation,
  useDeletePersonMutation,
  useCreatePersonMutation
} = personApi
