
import { apiSlice } from '../../api/apiSlice'
// Automatically created using reduxslicer by Simo Virokannas, 2024

export const personpermissionApi = apiSlice.injectEndpoints ({ 
  endpoints: (builder) => ({
    listPersonPermissions: builder.query ({
      query: (params) => `v2/personpermission/${params ? "?" + new URLSearchParams(Object.entries(params)).toString() : ""}`,
      keepUnusedDataFor: 1,
      providesTags: ["PersonPermission"]
    }),
    getPersonPermission: builder.query ({
      query: (personpermission_id) => `v2/personpermission/${personpermission_id}/`,
      keepUnusedDataFor: 1,
      providesTags: ["PersonPermission"]
    }),

    updatePersonPermission: builder.mutation ({
      query: ({personpermission_id, data}) => ({
        url: `v2/personpermission/${personpermission_id}/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ["PersonPermission"]
    }),
    deletePersonPermission: builder.mutation ({
      query: (personpermission_id) => ({
        url: `v2/personpermission/${personpermission_id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ["PersonPermission"]
    }),
    createPersonPermission: builder.mutation ({
      query: (data) => ({
        url: `v2/personpermission/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ["PersonPermission"]
    }),
  })
})


export const {
  useListPersonPermissionsQuery,
  useGetPersonPermissionQuery,

  useUpdatePersonPermissionMutation,
  useDeletePersonPermissionMutation,
  useCreatePersonPermissionMutation
} = personpermissionApi
